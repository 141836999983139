import React, { Fragment } from 'react'
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import Img from 'gatsby-image'
import Images from '../img/Images'
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from 'react-socks'
import CustomSelector from '../components/customSelector'
import BodyContentComponent from './bodyContentComponent'
import {SocialLine} from './socialComponents'
import SideBarComponent from './sideBarComponent'
import StickySidebar from '../common/stickybar/sticky-sidebar'

const CustomOption = ({ innerProps, isDisabled }) =>
	!isDisabled ? (
		<div {...innerProps}>{/* your component internals */}</div>
	) : null;

class ContentBlock extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			selectField: ''
		}
	}

	handleChange = (selectField) => {
		this.setState({ selectField })
		navigate(
			selectField.value,
			{ replace: false }
		)
	}

	getHash = (title) => {
		return title
		.replace(/[^\w\s]/gi, '')
		.toLowerCase()
		.replace(/ /gi, '-');
	}

	handleScroll = (e) => {
        let fromTop = window.scrollY;
		this.mainNavLinks.forEach(link => {
			let section;
			// let section = document.querySelector(link.hash);
			if (section) {
				if (section.offsetTop <= fromTop + 200 &&
					section.offsetTop + section.offsetHeight > fromTop
				) {
					link.classList.add("current")
				} else {
					link.classList.remove("current")
				}
			}
		})
    }

	// componentDidMount() {
	// 	if(document && window){
	// 		this.mainNavLinks = document.querySelectorAll(".sidebar a") || []
	// 		this.mainSections = document.querySelectorAll(".main section") || []

	// 		if(this.mainNavLinks.length > 0) {
	// 			window.addEventListener('scroll', this.handleScroll)
	// 			new StickySidebar('#sidebar', {
	// 		        containerSelector: '#main-content',
	// 		        innerWrapperSelector: '#sidebar__inner',
	// 		        topSpacing: 20,
	// 		        bottomSpacing: 20
	// 		    })
	// 		}
	// 	}
    // }

    componentWillUnmount() {
    	if(document && window){
        	window.removeEventListener('scroll', this.handleScroll)
    	}
    }


	render() {
		console.log(this.props.content);
		const that = this
		const { bodyContent, title } = this.props.content;
		const { pathname } = this.props.location;

		const headers = bodyContent.map((obj, i) => {
			if (obj && 'keyMessage' in obj) {
			let hash = this.getHash(obj.keyMessage)
			return <p key={i} className=''>
				<a href={`#${hash}`}>
					{`${obj.keyMessage}`}
				</a>
			</p>
			}
		})

		const url = pathname;

		const options = bodyContent.reduce(function(result, obj) {
			if (obj && obj.keyMessage) {
				result.push({ value: `${url}#${that.getHash(obj.keyMessage)}` , label: `- ${obj.keyMessage}` })
			} else if(obj && obj.sectionTitle) {
				const displayTitle = obj.sectionTitle.split('_').join(' ')
				result.push({ value: `${url}#${that.getHash(obj.sectionTitle)}` , label: `- ${displayTitle}` })
			}
			return result;
		}, [])
		return (
			<BreakpointProvider>
				<Breakpoint large up>
					<div className='content_header_wrapper'>
						<div className='centre-container -half-padding-top'>
							<div className="inner grid-ten -centre">
								<div className='top_header'>
									<div className='top_header_content'>
										<h1 className='title'>{this.props.content.title}</h1>
									</div>
									<div className='grid-container grid-full'>
										<div className="grid-half social-text">
											<h5>Share this page</h5>
											<SocialLine 
												path={pathname}
											/>
										</div>

										<div className='grid-half page-links'>
											<div className="diagonal">
												<h3>On this page</h3>
												{headers}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='centre-container'>
						<div className="inner grid-full">
							<div className="content-wrapper" id='main-content'>
								<div className="sidebar-container" id='sidebar'>
									<div className="sidebar" id='sidebar__inner'>
										<h5>{this.props.content.title }</h5>
										<SideBarComponent content={bodyContent} />
									</div>
								</div>
								<div className="main">
									<BodyContentComponent
										content={bodyContent}
										social
										path={pathname}
									/>
								</div>
							</div>
						</div>
					</div>
				</Breakpoint>

				<Breakpoint medium down>
					<div className='mobile-header-wrapper'>
						<div className='centre-container -half-padding-top'>
							<div className="inner grid-ten -centre">
								<div className='mobile-header-inner'>
									<h1 className='title'>{this.props.content.title}</h1>
									<div className="social-text-mobile">
										<h5>Share this page</h5>
										<SocialLine 
											path={pathname}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="mobile-diagonal-wrapper">
						<div className="diagonal">
							<div className='centre-container -remove-padding'>
								<div className="inner grid-ten -centre">
									<h4>On this page</h4>
										{headers}
								</div>
							</div>
						</div>
					</div>
					<div className='content-select-wrapper' id='mySelector'>
						<CustomSelector
							value={this.state.selectField}
							onChange={this.handleChange}
							options={options}
							placeholder="Jump to section..."
						/>
					</div>
					<div className='mobile-view-wrapper'>
						<div className='centre-container'>
							<div className="inner grid-ten -centre">
								<div className='mobile-view-content'>
									<BodyContentComponent
										content={bodyContent}
										social
										path={pathname}
									/>
								</div>
							</div>
						</div>
					</div>
				</Breakpoint>
			</BreakpointProvider>
		)
	}
}

ContentBlock.propTypes = {
	content: PropTypes.object
}

ContentBlock.defaultProps = {
	content: {}
}

export default ContentBlock
